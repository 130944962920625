<template>
    <div class="stage-inner-container-padded">
        <b-row>
            <b-col cols="12">
                <h2>Work Order Closeout</h2>
            </b-col>
        </b-row>
        <b-row class="sticky-header">
            <b-col cols="12" class=" mt-1  mb-1">
                <!-- Table Goes Here -->
                <b-row>
                    
                    <b-col cols="5">
                         <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            first-text="First"
                            prev-text="Prev"
                            next-text="Next"
                            last-text="Last"
                        ></b-pagination>
                    </b-col>
                    <b-col cols="1">
                        {{totalRows}} Rows 
                    </b-col>
                    <b-col cols="4">
                          <b-form-group>
                            <b-form-checkbox-group
                                id="closeout-stage-filtering"
                                v-model="stageSelections"
                                :options="stageOptions"
                                name="closeout-stages"
                                @change="debouncedUpdate"
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="2">
                        <b-button-toolbar key-nav aria-label="Selection Options">
                            <b-dropdown class="mx-1" right text="Select">
                                <b-dropdown-item @click="selectAll">All Rows</b-dropdown-item>
                                <b-dropdown-item @click="deselectAll">Unselect All Rows</b-dropdown-item>
                            </b-dropdown>
                            <b-dropdown class="mx-1" right text="Batch Processing">
                                <b-dropdown-item @click="batchMarkClosed">Close All Selected Eligible Work Orders</b-dropdown-item>
                                <b-dropdown-item @click="batchPDFDownload">Download PDF For All Selected Eligible Work Orders</b-dropdown-item>
                                <b-dropdown-item @click="batchMarkSent">Mark SENT For All Selected Eligbile Work Orders</b-dropdown-item>
                                <b-dropdown-item @click="batchMarkBilled">Mark BILLED For All Selected Eligbile Work Orders</b-dropdown-item>
                                <b-dropdown-item @click="batchMarkPaid">Mark PAID For All Selected Eligbile Work Orders</b-dropdown-item>
                            </b-dropdown>
                        </b-button-toolbar>
                    </b-col>
                </b-row>

                <b-progress v-if="showProgress" :max="progressMax" height="2rem">
                    <b-progress-bar :value="progressCurrent">
                        <span>{{progressTitle}} <strong>{{ progressCurrent }} / {{ progressMax }}</strong></span>
                    </b-progress-bar>
                </b-progress>
                <b-row class="mt-1">
                    <b-col>
                        <b-dropdown block squared split :split-variant="(sortBy == 'group_name') ? 'outline-success' :'outline-primary'" :variant="(filterBy == 'group_name') ? 'success' :'primary'" @click="setOrderBy('group_name')">
                            <template #button-content>
                                Group Name
                                <b-icon-dash v-if="sortBy != 'group_name'"></b-icon-dash>
                                <b-icon-arrow-down v-else-if="sortBy == 'group_name' && sortDesc"></b-icon-arrow-down>
                                <b-icon-arrow-up v-else-if="sortBy == 'group_name' && !sortDesc"></b-icon-arrow-up>
                            </template>
                            <b-dropdown-text><b>Filter Options</b></b-dropdown-text>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-form>
                                <b-form-group label="Group Name Contains" label-for="dropdown-form-term-group-name" @submit.stop.prevent>
                                    <b-form-input
                                        id="dropdown-form-term-group-name"
                                        v-model="filterValues.group_name"
                                        size="sm"
                                        placeholder=""
                                    ></b-form-input>
                                </b-form-group>
                                <b-button variant="primary" size="sm" @click="setFilterBy('group_name')">Apply</b-button>
                                <b-button variant="warning" size="sm" @click="clearFilter">Clear</b-button>
                            <b-dropdown-divider></b-dropdown-divider>
                                <b-button variant="info" size="sm" @click="setNullFor('group_name')">Search For NULL Values</b-button>
                            </b-dropdown-form>
                        </b-dropdown>
                    </b-col>
                    <b-col>
                        <b-dropdown block squared split :split-variant="(sortBy == 'product_code') ? 'outline-success' :'outline-primary'" :variant="(filterBy == 'product_code') ? 'success' :'primary'" @click="setOrderBy('product_code')">
                            <template #button-content>
                                Product Code
                                <b-icon-dash v-if="sortBy != 'product_code'"></b-icon-dash>
                                <b-icon-arrow-down v-else-if="sortBy == 'product_code' && sortDesc"></b-icon-arrow-down>
                                <b-icon-arrow-up v-else-if="sortBy == 'product_code' && !sortDesc"></b-icon-arrow-up>
                            </template>
                            <b-dropdown-text><b>Filter Options</b></b-dropdown-text>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-form>
                                <b-form-group label="Product Code Contains" label-for="dropdown-form-term-product-code" @submit.stop.prevent>
                                    <b-form-input
                                        id="dropdown-form-term-product-code"
                                        v-model="filterValues.product_code"
                                        size="sm"
                                        placeholder=""
                                    ></b-form-input>
                                </b-form-group>
                                <b-button variant="primary" size="sm" @click="setFilterBy('product_code')">Apply</b-button>
                                <b-button variant="warning" size="sm" @click="clearFilter">Clear</b-button>
                            </b-dropdown-form>
                        </b-dropdown>
                    </b-col>
                    <b-col>
                        <b-dropdown block squared split :split-variant="(sortBy == 'event_tstamp') ? 'outline-success' :'outline-primary'" :variant="(filterBy == 'event_tstamp') ? 'success' :'primary'" @click="setOrderBy('event_tstamp')">
                            <template #button-content>
                                Event Date
                                <b-icon-dash v-if="sortBy != 'event_tstamp'"></b-icon-dash>
                                <b-icon-arrow-down v-else-if="sortBy == 'event_tstamp' && sortDesc"></b-icon-arrow-down>
                                <b-icon-arrow-up v-else-if="sortBy == 'event_tstamp' && !sortDesc"></b-icon-arrow-up>
                            </template>
                            <b-dropdown-text><b>Filter Options</b></b-dropdown-text>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-form>
                                <b-form-group label="Event Date Contains" label-for="dropdown-form-term-event-tstamp" @submit.stop.prevent>
                                    <b-form-input
                                        id="dropdown-form-term-event-tstamp"
                                        v-model="filterValues.event_tstamp"
                                        size="sm"
                                        placeholder=""
                                    ></b-form-input>
                                </b-form-group>
                                <b-button variant="primary" size="sm" @click="setFilterBy('event_tstamp')">Apply</b-button>
                                <b-button variant="warning" size="sm" @click="clearFilter">Clear</b-button>
                            </b-dropdown-form>
                        </b-dropdown>
                    </b-col>
                    <b-col>
                        <b-dropdown block squared split :split-variant="(sortBy == 'site_address') ? 'outline-success' :'outline-primary'" :variant="(filterBy == 'site_address') ? 'success' :'primary'" @click="setOrderBy('site_address')">
                            <template #button-content>
                                Site Info
                                <b-icon-dash v-if="sortBy != 'site_address'"></b-icon-dash>
                                <b-icon-arrow-down v-else-if="sortBy == 'site_address' && sortDesc"></b-icon-arrow-down>
                                <b-icon-arrow-up v-else-if="sortBy == 'site_address' && !sortDesc"></b-icon-arrow-up>
                            </template>
                            <b-dropdown-text><b>Filter Options</b></b-dropdown-text>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-form>
                                <b-form-group label="Site Info Contains" label-for="dropdown-form-term-site-address" @submit.stop.prevent>
                                    <b-form-input
                                        id="dropdown-form-term-site-address"
                                        v-model="filterValues.site_address"
                                        size="sm"
                                        placeholder=""
                                    ></b-form-input>
                                </b-form-group>
                                <b-button variant="primary" size="sm" @click="setFilterBy('site_address')">Apply</b-button>
                                <b-button variant="warning" size="sm" @click="clearFilter">Clear</b-button>
                            </b-dropdown-form>
                        </b-dropdown>
                    </b-col>
                    <b-col>
                        <b-dropdown block squared split :split-variant="(sortBy == 'billing_contact') ? 'outline-success' :'outline-primary'" :variant="(filterBy == 'billing_contact') ? 'success' :'primary'" @click="setOrderBy('billing_contact')">
                            <template #button-content>
                                Billing Info
                                <b-icon-dash v-if="sortBy != 'billing_contact'"></b-icon-dash>
                                <b-icon-arrow-down v-else-if="sortBy == 'billing_contact' && sortDesc"></b-icon-arrow-down>
                                <b-icon-arrow-up v-else-if="sortBy == 'billing_contact' && !sortDesc"></b-icon-arrow-up>
                            </template>
                            <b-dropdown-text><b>Filter Options</b></b-dropdown-text>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-form>
                                <b-form-group label="Billing Info Contains" label-for="dropdown-form-term-billing-contact" @submit.stop.prevent>
                                    <b-form-input
                                        id="dropdown-form-term-billing-contact"
                                        v-model="filterValues.billing_contact"
                                        size="sm"
                                        placeholder=""
                                    ></b-form-input>
                                </b-form-group>
                                <b-button variant="primary" size="sm" @click="setFilterBy('billing_contact')">Apply</b-button>
                                <b-button variant="warning" size="sm" @click="clearFilter">Clear</b-button>
                            </b-dropdown-form>
                        </b-dropdown>
                    </b-col>
                    <b-col>
                        <b-dropdown block squared split :split-variant="(sortBy == 'utility_name') ? 'outline-success' :'outline-primary'" :variant="(filterBy == 'utility_name') ? 'success' :'primary'" @click="setOrderBy('utility_name')">
                            <template #button-content>
                                Utility Info
                                <b-icon-dash v-if="sortBy != 'utility_name'"></b-icon-dash>
                                <b-icon-arrow-down v-else-if="sortBy == 'utility_name' && sortDesc"></b-icon-arrow-down>
                                <b-icon-arrow-up v-else-if="sortBy == 'utility_name' && !sortDesc"></b-icon-arrow-up>
                            </template>
                            <b-dropdown-text><b>Filter Options</b></b-dropdown-text>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-form>
                                <b-form-group label="Utility Info Contains" label-for="dropdown-form-term-utility-name" @submit.stop.prevent>
                                    <b-form-input
                                        id="dropdown-form-term-utility-name"
                                        v-model="filterValues.utility_name"
                                        size="sm"
                                        placeholder=""
                                    ></b-form-input>
                                </b-form-group>
                                <b-button variant="primary" size="sm" @click="setFilterBy('utility_name')">Apply</b-button>
                                <b-button variant="warning" size="sm" @click="clearFilter">Clear</b-button>
                            </b-dropdown-form>
                        </b-dropdown>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-overlay :show="isBusy" rounded="sm">
            <b-card v-if="isBusy" title="Loading"></b-card>
        </b-overlay>
        <wo-item v-for="row in tableValues" v-bind:key="row.fulcrum_id" :wo="row" :ref="'wo_' + row.fulcrumID"></wo-item>
        <b-row>
            <b-col cols="12">
                <b-button-toolbar justify>
                    <b-button variant="info" @click="$router.go(-1)">Exit</b-button>
                </b-button-toolbar>
            </b-col>
        </b-row>
        
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 60000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');
var fileDownload = require('js-file-download');

import WOItem from '@/components/subcomponents/WorkOrder.vue'

export default {
    name: 'closeout',
    components: {
        'wo-item': WOItem
    },
    props:{
    },
    data(){
        return{
            showProgress: false,
            progressTitle: '',
            progressCurrent: 0,
            progressMax: 0,
            totalRows: 0,
            currentPage: 1,
            perPage: 100,
            isBusy: false,
            sortBy: 'event_tstamp',
            sortDesc: false,
            sortDirection: '',
            filterBy: null,
            filterValue: null,
            filterValues:{
                group_name: null,
                product_code: null,
                event_date: null,
                site_address: null,
                billing_contact: null,
                utility_name: null
            },
            stageOptions:[
                {text:'Done', value: 'showDone'},
                {text:'Closed', value: 'showClosed'},
                {text:'Downloaded', value: 'showDownloaded'},
                {text:'Sent', value: 'showSent'},
                {text:'Billed', value: 'showBilled'},
            ],
            stageSelections:['showDone'],
            selected: [],
            rawFetchValue: [],
            allValues: [],
            tableValues: [],
            fields: [
                { key: 'billingInfo', label: "Billing Info", sortable: true },
                { key: 'utilityInfo', label: "Utility Info", sortable: true },
                { key: 'siteDevInfo', label: "Site / Device Info", sortable: true},
                // { key: 'serviceAddress', label: "Service Address", sortable: true },
                { key: 'eventTstamp', label: "Event Date", sortable: true },
                // { key: 'technician', label: "Technician", sortable: false },
                { key: 'eventName', label: "Task", sortable: true },
                { key: 'finalTestResult', label: "Test Result (if Test)", sortable: true },
                // { key: 'deviceInfo', label: "Device Info", sortable: true },
                { key: 'productCode', label: "Product Code", sortable: false },
                { key: 'groupName', label: "Group Name", sortable: true },
                { key: 'actions', label: "Actions", sortable: false },
                
            ],
            filter: {
                needSent: false,
                needBilling: false,
                needPaid: false
            }
        }
    },
    methods: {
        clearFilter(){
            this.filterBy = null;
            this.filterValue = null;
            this.filterValues = {
                group_name: null,
                product_code: null,
                event_date: null,
                site_address: null,
                billing_contact: null,
                utility_name: null
            };
            this.debouncedUpdate();
        },
        setFilterBy(value){
            this.filterBy = value;
            this.filterValue = this.filterValues[value];
            this.debouncedUpdate();
        },
        setNullFor(value){
            this.filterBy = value;
            this.filterValues[value] = "NULL";
            this.filterValue = "NULL";
            this.debouncedUpdate();
        },
        setOrderBy(value){
            this.sortBy = value;
            this.sortDesc = !this.sortDesc;
        },
        setProgress(title, cur, total){
            this.progressTitle = title;
            this.progressMax = total;
            this.progressCurrent = cur;
        },
        batchPDFDownload(){
            // console.log(this.selected)
            this.showProgress = true;
            var items = this.tableValues.filter((itm) => { return this.$refs['wo_' + itm.fulcrumID][0].isSelected && this.$refs['wo_' + itm.fulcrumID][0].downloadEligible })
            console.log(items)
            if(items.length > 0)
                this.downloadNextPDF(items, 0);
            else
                this.showProgress = false;
        },
        downloadNextPDF(selection, index){
            this.setProgress("Downloading PDFs:", index + 1, selection.length);
            console.log(`Downloading ${index} of ${selection.length}`)
            var itm = selection[index];
            var cb = _.once(()=>{
               index++;
                if(selection.length - 1 >= index){
                    this.downloadNextPDF(selection, index);
                }else{
                    this.showProgress = false;
                }
            });
            this.$refs['wo_' + itm.fulcrumID][0].markAsDownloaded(cb);
        },
        batchMarkSent(){
            this.showProgress = true;
            var items = this.tableValues.filter((itm) => { return this.$refs['wo_' + itm.fulcrumID][0].isSelected && this.$refs['wo_' + itm.fulcrumID][0].allChildrenClosed && !this.$refs['wo_' + itm.fulcrumID][0].wo.sent.completed})
            console.log(items)
            if(items.length > 0)
                this.markSentNext(items, 0);
            else
                this.showProgress = false;
        },
        markSentNext(selection, index){
            this.setProgress("Marking Sent:", index + 1, selection.length);
            console.log(`Marking as Sent ${index} of ${selection.length}`)
            var itm = selection[index];
            var cb = _.once(()=>{
                index++;
                if(selection.length - 1 >= index){
                    this.markSentNext(selection, index);
                }else{
                    this.showProgress = false;
                }
            });
            this.$refs['wo_' + itm.fulcrumID][0].markAsSent(cb);
        },
        batchMarkBilled(){
            this.showProgress = true;
            var items = this.tableValues.filter((itm) => { return this.$refs['wo_' + itm.fulcrumID][0].isSelected && this.$refs['wo_' + itm.fulcrumID][0].allChildrenClosed && this.$refs['wo_' + itm.fulcrumID][0].wo.sent.completed && !this.$refs['wo_' + itm.fulcrumID][0].wo.billed.completed })
            console.log(items)
            if(items.length > 0)
                this.markBilledNext(items, 0);
            else
                this.showProgress = false;
        },
        markBilledNext(selection, index){
            this.setProgress("Marking Billed:", index + 1, selection.length);
            console.log(`Marking as Billed ${index} of ${selection.length}`)
             var itm = selection[index];
             var cb = _.once(()=>{
                index++;
                if(selection.length - 1 >= index){
                    this.markBilledNext(selection, index);
                }else{
                    this.showProgress = false;
                }
            });
            this.$refs['wo_' + itm.fulcrumID][0].markAsBilled(cb);
        },
        batchMarkClosed(){
            this.showProgress = true;
            var items = this.tableValues.filter((itm) => { return this.$refs['wo_' + itm.fulcrumID][0].isSelected && !this.$refs['wo_' + itm.fulcrumID][0].wo.closed.completed })
            console.log(items)
            if(items.length > 0)
                this.markClosedNext(items, 0);
            else
                this.showProgress = false;
        },
        markClosedNext(selection, index){
            this.setProgress("Marking Closed:", index + 1, selection.length);
            console.log(`Marking as Closed ${index} of ${selection.length}`)
             var itm = selection[index];
             var cb = _.once(()=>{
                index++;
                if(selection.length - 1 >= index){
                    this.markClosedNext(selection, index);
                }else{
                    this.showProgress = false;
                }
            });
            this.$refs['wo_' + itm.fulcrumID][0].markAsClosed(cb);
        },
        batchMarkPaid(){
            var items = this.selected.filter((itm) => { return this.$refs['wo_' + itm.fulcrumID][0].isSelected && this.$refs['wo_' + itm.fulcrumID][0].wo.billed.completed && !this.$refs['wo_' + itm.fulcrumID][0].wo.paid.completed })
            console.log(items)
            if(items.length > 0)
                this.markPaidNext(items, 0);
            else
                this.showProgress = false;
        },
        markPaidNext(selection, index){
            this.setProgress("Marking Paid:", index + 1, selection.length);
            console.log(`Marking as Paid ${index} of ${selection.length}`)
            var itm = selection[index];
            var cb = _.once(()=>{
                index++;
                if(selection.length - 1 >= index){
                    this.markPaidNext(selection, index);
                }else{
                    this.showProgress = false;
                }
            });
            this.$refs['wo_' + itm.fulcrumID][0].markAsPaid(cb);
        },
        getDeviceLink(deviceID){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: 'devices'});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + deviceID
            }
        },
        onRowSelected(items) {
            this.selected = items
        },
        selectAll(){
            this.tableValues.forEach((itm)=>{
                this.$refs['wo_' + itm.fulcrumID][0].isSelected = true;
            })
        },
        deselectAll(){
            this.tableValues.forEach((itm)=>{
                this.$refs['wo_' + itm.fulcrumID][0].isSelected = false;
            })
        },
        goToDevice(){
            var thisFormType = 'devices';
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: thisFormType});
            if(defaultFormForType == null){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else if(defaultFormForType.length == 0){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else{
                this.$router.push({path: '/home/form/' + defaultFormForType[0].id + '/' + this.deviceID })
            }
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null, timer = 20){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: timer * 1000,
                appendToast: true
            })
        },
        checkBoxFieldHandler(fieldVal, markedOn, markedBy){
            var value = {
                completed: false,
                by: markedBy,
                on: markedOn
            };
            if(fieldVal == null || fieldVal == false){
                value.completed = false;
            }else{
                value.completed = true;
            }
            return value;
        },
        sentCheckBoxHandler(fieldVal, markedOn, markedBy){
            var value = {
                downloadComplete: false,
                completed: false,
                by: markedBy,
                on: markedOn
            };
            if(fieldVal == null || fieldVal == false){
                value.completed = false;
            }else{
                value.completed = true;
            }
            return value;
        },
        arrayToList(array){
            var out = "";
            if(array != null && _.isArray(array)){
                array.forEach((val)=>{
                    if(out == ""){
                        out = out + val;
                    }else{
                        out = out + "\n" + val;
                    }
                })
                return out;
            }else{
                return out;
            }
            
        },
        closeoutProvider(ctx){
            this.isBusy = true;
            console.log("Called Closeout Provider")
            console.log(ctx)
            var paramArray = [];
            paramArray.push(`page=${ctx.currentPage}`)
            paramArray.push(`perPage=${ctx.perPage}`)
            if(_.contains(this.stageSelections, 'showClosed')){
               paramArray.push(`showClosed=true`) 
            }else{
                paramArray.push(`showClosed=false`) 
            }
            if(_.contains(this.stageSelections, 'showDownloaded')){
               paramArray.push(`showDownloaded=true`) 
            }else{
                paramArray.push(`showDownloaded=false`) 
            }
            if(_.contains(this.stageSelections, 'showSent')){
               paramArray.push(`showSent=true`) 
            }else{
                paramArray.push(`showSent=false`) 
            }
            if(_.contains(this.stageSelections, 'showBilled')){
               paramArray.push(`showBilled=true`) 
            }else{
                paramArray.push(`showBilled=false`) 
            }
            if(_.contains(this.stageSelections, 'showDone')){
               paramArray.push(`showDone=true`) 
            }else{
                paramArray.push(`showDone=false`) 
            }
            var parsedSort = null;
            if(ctx.sortBy != ""){
                if(ctx.sortBy == "finalTestResult"){
                    parsedSort = "final_test_result";
                }else if(ctx.sortBy == "billingInfo"){
                    parsedSort = "billing_contact";
                }else if(ctx.sortBy == "eventTstamp"){
                    parsedSort = "event_tstamp";
                }else if(ctx.sortBy == "deviceInfo"){
                    parsedSort = "device_info";
                }else if(ctx.sortBy == "eventName"){
                    parsedSort = "event_name";
                }else if(ctx.sortBy == "siteDevInfo"){
                    parsedSort = "site_address";
                }else if(ctx.sortBy == "groupName"){
                    parsedSort = "group_name";
                }else if(ctx.sortBy == "utilityInfo"){
                    parsedSort = "utility_name";
                }
                paramArray.push(`sortBy=${ctx.sortBy}`)
                paramArray.push(`sortDesc=${ctx.sortDesc}`)
            }
            if(ctx.filter.by != null){
                paramArray.push(`filterBy=${ctx.filter.by}`);
                paramArray.push(`filterValue=${ctx.filter.value}`);
            }
            let promise = instance.get(process.env.VUE_APP_API_BASE_URL + `/atils/closeout/list.json?${paramArray.join("&")}`)
            return promise.then((response)=>{
                this.totalRows = response.data.meta.totalRows;
                var tempTableVals = [];
                this.rawFetchValue = response.data.result.records;
                var parsable = response.data.result.records;
                parsable.forEach((row)=>{
                    var obj = {
                        fulcrumID: row.fulcrum_id,
                        eventID: row.event_id,
                        billingInfo: {
                            contact: row.billing_contact,
                            email: this.arrayToList(row.billing_contact_email),
                            address: row.billing_address,
                        },
                        utilityInfo:{
                            name: row.utility_name,
                            contact: row.utility_contact_full_name,
                            email: this.arrayToList(row.utility_contact_email),
                            phone: this.arrayToList(row.utility_contact_phone_number),
                            address: row.utility_address
                        },
                        serviceAddress: row.site_address,
                        eventTstamp: row.event_tstamp,
                        technician: row.technician,
                        eventName:  row.event_name,
                        finalTestResult: row.final_test_result,
                        deviceInfo: row.device_info,
                        replacementDeviceInfo: row.replacement_device_info,
                        deviceID: row.device_id,
                        replacementDeviceID: row.replacement_device_id,
                        productCode: row.product_code,
                        groupName: row.group_name,
                        groupNameID: row.group_name_id,
                        sent: this.sentCheckBoxHandler(row.sent, row.sent_marked_on, row.sent_marked_by_name),
                        billed: this.checkBoxFieldHandler(row.billed, row.billed_marked_on, row.billed_marked_by_name),
                        paid: this.checkBoxFieldHandler(row.paid, row.paid_marked_on, row.paid_marked_by_name),
                        closed: this.checkBoxFieldHandler(row.closed, row.closed_marked_on, row.closed_marked_by_name),
                        downloaded: this.checkBoxFieldHandler(row.downloaded, row.downloaded_marked_on, row.downloaded_marked_by_name),
                        children: row.child_events
                    };

                    obj.children.forEach((child)=>{
                        child.sent = this.sentCheckBoxHandler(child.sent, child.sent_marked_on, child.sent_marked_by_name);
                        child.billed = this.checkBoxFieldHandler(child.billed, child.billed_marked_on, child.billed_marked_by_name);
                        child.paid = this.checkBoxFieldHandler(child.paid, child.paid_marked_on, child.paid_marked_by_name);
                        child.closed = this.checkBoxFieldHandler(child.closed, child.closed_marked_on, child.closed_marked_by_name);
                        child.downloaded = this.checkBoxFieldHandler(child.downloaded, child.downloaded_marked_on, child.downloaded_marked_by_name);
                    })
                    tempTableVals.push(obj);
                })
                this.tableValues = tempTableVals;
                this.isBusy = false;
                return(tempTableVals) 
            }).catch((err)=>{
                // TODO: An Error Has Occured Do Something Useful With it. Resp: "No" - TR 2020-09-28
                console.log(`Failed To Fetch Closeout Data`)
                console.log(err)
                this.makeToast("Failed To Fetch Closeout Data", "An error has occured durring lookup of the records that require closeout.", 'danger', 30)
                this.isBusy = false;
                return(err);
            })
        },
        triggerTableDataRefresh(){
            this.updateListing();
        },
        updateListing(){
            var opt = {
                currentPage: this.currentPage,
                perPage: this.perPage,
                filter: {
                    by: this.filterBy,
                    value: this.filterValues[this.filterBy]
                },
                sortBy: this.sortBy,
                sortDesc: this.sortDesc
            };

            this.tableValues = this.closeoutProvider(opt)
        }
    },
    watch:{
        currentPage: function (val) {
            this.debouncedUpdate();
        },
        perPage: function (val) {
            this.debouncedUpdate();
        },
        sortDesc: function (val) {
            this.debouncedUpdate();
        }
    },
    computed: {

    },
    created(){
        this.debouncedUpdate = _.debounce(()=>{this.updateListing(); console.log("Debounced Update Called")}, 1000);
    },
    mounted(){
        
    },
    beforeDestroy(){
        
    },
    beforeMount (){
        this.triggerTableDataRefresh();
    },
    beforeCreate(){
        //this.initBeforeCreate();
    },
}
</script>

<style scoped>
.array-spaced{
  margin-bottom: 0.5rem;
}
.fgroup-spacer{
    width: 100%;
    height: 1px;
    margin-bottom: 0.5rem;
}
.blue-bg{
    background-color: #ebf5ff;
    border-radius: 1em;
    border-width: 0.5em;
    padding: 0.5em;
}
.explain-fail{
    font-size: 0.75em;
}
.larger-header{
    font-size: 1.5em;
}
.sticky-header{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1010;
}
</style>