<template>
    <div>
        <b-row class="p-1 mb-2 text-black " v-bind:class="{ 'main-wo-container-selected': isSelected, 'main-wo-container': !isSelected}" v-on:click.self="toggleSelected()">
            <b-col cols="4">
                <b-row>
                    <b-col md="4" lg="3" xl="2">
                        Site:
                    </b-col>
                    <b-col md="8" lg="9" xl="10">
                        <b>{{wo.serviceAddress}}</b>
                    </b-col>
                    <b-col md="4" lg="3" xl="2">
                        Device:
                    </b-col>
                    <b-col md="8" lg="9" xl="10">
                        <b-link v-b-popover.hover.left="'View This Device (Opens In New Tab)'" target="_blank" :to="getDeviceLink(wo.deviceID)">{{wo.deviceInfo}}</b-link>
                    </b-col>
                    <b-col md="4"  lg="3" xl="2" v-on:click.self="toggleSelected()">
                        Group:
                    </b-col>
                    <b-col md="8" lg="9" xl="10" v-on:click.self="toggleSelected()">
                        {{wo.groupName}}
                    </b-col>
                    <b-col md="4"  lg="3" xl="2" v-on:click.self="toggleSelected()">
                        Product:
                    </b-col>
                    <b-col md="8" lg="9" xl="10" v-on:click.self="toggleSelected()">
                        {{wo.productCode}}
                    </b-col>
                    <b-col md="4"  lg="3" xl="2" v-on:click.self="toggleSelected()">
                        Event:
                    </b-col>
                    <b-col md="8" lg="9" xl="10" v-on:click.self="toggleSelected()">
                        {{parseTimestampToDate(wo.eventTstamp)}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="4" v-on:click="toggleSelected()">
                <b-row>
                    
                    <b-col md="4"  lg="3" xl="2">
                        Billing:
                    </b-col>
                    <b-col md="8" lg="9" xl="10">
                        {{wo.billingInfo.contact}} {{wo.billingInfo.email}}<br>
                        {{wo.billingInfo.address}}
                    </b-col>
                    <b-col md="4"  lg="3" xl="2">
                        Utility:
                    </b-col>
                    <b-col md="8" lg="9" xl="10">
                        {{wo.utilityInfo.email}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="4">
                <b-row no-gutters v-for="child in wo.children" :key="child.event_id" class="p-1 mb-1 mt-1 bg-secondary text-white">
                    <b-col lg="4" v-if=" (child.final_test_result != null && child.final_test_result != '') || (child.survey_notes != null && child.survey_notes != '') ">
                        {{child.event_name}} - {{child.final_test_result}}{{child.survey_notes}}
                    </b-col>
                    <b-col lg="4" v-else>
                        {{child.event_name}}
                    </b-col>
                    <b-col lg="3">
                        {{parseTimestampToDate(child.event_tstamp)}}
                    </b-col>
                    <b-col cols="5" class="text-center line-up-col">
                            <b-button size="sm" class="py-0 ml-1 line-up-btn" variant="info" target="_blank" :to="getTestLink(child.event_id)" v-if="child.event_name == 'BFPA Test' && $store.getters.checkUIPC({path: ['viewTest', 'access'] })">View</b-button>
                            <b-button size="sm" class="py-0 ml-1 line-up-btn" variant="info" target="_blank" :to="getSurveyLink(child.event_id)" v-if="(child.event_name == 'Survey' || child.event_name == 'Field Update') && $store.getters.checkUIPC({path: ['viewSurvey', 'access'] })">View</b-button>
                            <b-button size="sm" class="py-0 ml-1 line-up-btn" variant="info" target="_blank" :to="getReplacementLink(child.event_id)" v-if="child.event_name == 'Replacement or Install' && $store.getters.checkUIPC({path: ['viewReplacementInstall', 'access'] })">View</b-button>
                            <b-button size="sm" class="py-0 ml-1 line-up-btn" variant="info" target="_blank" :to="getCommentLink(child.event_id)" v-if="child.event_name == 'Task Comment' && $store.getters.checkUIPC({path: ['viewTaskComment', 'access'] })">View</b-button>
                            <b-button size="sm" class="py-0 ml-1 line-up-btn" variant="info" target="_blank" @click="downloadPDF(child)" v-if="child.event_name == 'BFPA Test' && (child.attachment_id == null || !child.closed.completed) && $store.getters.checkUIPC({path: ['viewTaskComment', 'access'] })">Download</b-button>
                            <b-button size="sm" class="py-0 ml-1 line-up-btn" variant="warning" target="_blank" @click="downloadPDF(child)" v-if="child.event_name == 'BFPA Test' && child.attachment_id != null && child.closed.completed && $store.getters.checkUIPC({path: ['viewTaskComment', 'access'] })">Download</b-button>
                            <b-button size="sm" class="py-0 ml-1 line-up-btn" variant="info" @click="openEmailModal(child)" v-if="child.event_name == 'BFPA Test' && child.attachment_id != null && child.closed.completed && wo.sent.by == null && $store.getters.checkUIPC({path: ['viewTest', 'actions', 'emailReport'] })" >Email</b-button>
                            <b-button size="sm" class="py-0 ml-1 line-up-btn" variant="dark" @click="openEmailModal(child)" v-if="child.event_name == 'BFPA Test' && child.attachment_id != null && child.closed.completed && wo.sent.by != null && $store.getters.checkUIPC({path: ['viewTest', 'actions', 'emailReport'] })" >Email</b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" class="px-0 py-0 mx-0 my-0">
                <b-row no-gutters class="px-0 py-0 mx-0 my-0">
                    <b-col cols="2" class="px-0 py-0 mx-0 my-0">
                    </b-col>
                    <b-col cols="2" class="pr-1 py-0 mx-0 my-0" >
                        <b-button size="sm" class="p-0" squared block disabled variant="secondary">Done Since {{lastModified}}</b-button>
                    </b-col>
                    <b-col cols="2" class="pr-1 py-0 mx-0 my-0">
                        <b-button size="sm" class="p-0" squared block variant="success" @click="markAsClosed()" v-if="!allChildrenClosed && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowClose'] })">Close Work Order</b-button>
                        <b-button size="sm" class="p-0" squared block variant="secondary" disabled v-else>WO Closed On {{parseTimestampToDateTime(wo.closed.on)}} By {{wo.closed.by}}</b-button>
                    </b-col>
                    <b-col cols="2" class="pr-1 py-0 mx-0 my-0">
                        <b-button size="sm" class="p-0" squared block disabled variant="secondary" v-if="!downloadEligible && allChildrenClosed && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowDownload'] })">No Downloads</b-button>
                        <b-button size="sm" class="p-0" squared block disabled variant="primary" v-else-if="!allChildrenClosed && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowDownload'] })">Close Before Download</b-button>
                        <b-button size="sm" class="p-0" squared block variant="primary" v-else-if="downloadEligible && wo.downloaded.by == null && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowDownload'] })" @click="markAsDownloaded()">Download PDF</b-button>
                        <b-button size="sm" class="p-0" squared block variant="secondary" v-else-if="downloadEligible && wo.downloaded.by != null && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowDownload'] })" @click="markAsDownloaded()">Last PDF Download by {{wo.downloaded.by}} on {{parseTimestampToDateTime(wo.downloaded.on)}}</b-button>
                    </b-col>
                    <b-col cols="2" class="pr-1 py-0 mx-0 my-0">
                        <b-button size="sm" class="p-0" squared block variant="primary" v-if="allChildrenClosed && wo.sent.by == null && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowSend'] })" @click="markAsSent()">Mark Sent</b-button>
                        <b-button size="sm" class="p-0" squared block disabled variant="secondary" v-else-if="allChildrenClosed && wo.sent.by != null && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowSend'] })">Sent By {{wo.sent.by}} On {{parseTimestampToDateTime(wo.sent.on)}}</b-button>
                        <b-button size="sm" class="p-0" squared block disabled variant="primary" v-else-if="!allChildrenClosed && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowSend'] })">Close Before Marking Sent</b-button>
                    </b-col>
                    <b-col cols="2" class="pr-1 py-0 mx-0 my-0">
                        <b-button size="sm" class="p-0" squared block disabled variant="primary" v-if="allChildrenClosed && wo.sent.by == null && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowBill'] })" >Send Before Marking Billed</b-button>
                        <b-button size="sm" class="p-0" squared block disabled variant="secondary" v-else-if="allChildrenClosed && wo.sent.by != null && wo.billed.by != null && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowBill'] })" @click="markAsBilled()">Billed By {{wo.billed.by}} On {{parseTimestampToDateTime(wo.billed.on)}}</b-button>
                        <b-button size="sm" class="p-0" squared block variant="primary" v-else-if="allChildrenClosed && wo.sent.by != null && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowBill'] })" @click="markAsBilled()">Mark Billed</b-button>
                        <b-button size="sm" class="p-0" squared block disabled variant="primary" v-else-if="!allChildrenClosed && $store.getters.checkUIPC({path: ['closeouts', 'actions', 'allowBill'] })" >Close Before Marking Billed</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <email-test-modal :eventID="emailAgainstEventID" :requestOpen="openEmailModalReqCount" :markSent="markAsSent"></email-test-modal>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 30000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

const butils = require('../../libs/basicUtils.js');

var fileDownload = require('js-file-download');



import EmailTestReport from '@/components/subcomponents/EmailTestReport.vue'

export default {
    name: 'closeoutworkorder',
    components: {
        'email-test-modal': EmailTestReport,
    },
    props:{
        wo: {
            type: Object,
            default: () => { return {} }
        },
        eventCallback: {
            type: Function,
            default: () => { return (fulcID, pram)=>{console.log(`Missing Callback Prop Function On Workorder With Fulcrum ID: ${fulcID}`); console.log(pram);} } 
        },
    },
    data(){
        return{
            id: null,
            isSelected: false,
            emailAgainstEventID: null,
            openEmailModalReqCount: 0
        }
    },
    methods: {
        openEmailModal(itm){
            this.emailAgainstEventID = itm.event_id;
            this.openEmailModalReqCount++;
        },
        toggleSelected(){
            if(this.isSelected){
                this.isSelected = false;
            }else{
                this.isSelected = true;
            }
        },
        getDeviceLink(deviceID){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: 'devices'});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + deviceID
            }
        },
        getTestLink(eventID){
            return `/home/test/${eventID}`;
        },
        getSurveyLink(eventID){
            return `/home/survey/${eventID}`;
        },
        getReplacementLink(eventID){
            return `/home/replacement/${eventID}`;
        },
        getCommentLink(eventID){
            return `/home/comment/${eventID}`;
        },
        parseTimestampToDateTime(input){
            return butils.formatters.timestampToDateTime(input, true, false, '\r\n ');
        },
        parseTimestampToDate(input){
            return butils.formatters.timestampToDateTime(input, false, false);
        },
        markAsSent(callback = null){
            this.wo.children.forEach((itm)=>{
                var postData = {
                    sent: true
                };
                instance.post(process.env.VUE_APP_API_BASE_URL + "/atils/closeout/" + itm.event_id + ".json", postData)
                .then((response)=>{
                    itm.sent.completed = true;
                    itm.sent.by = "You";
                    itm.sent.on = new Date().toISOString();
                    this.wo.sent.completed = true;
                    this.wo.sent.by = "You";
                    this.wo.sent.on = new Date().toISOString();
                    if(callback != null){
                        callback('ok');
                    }
                })
                .catch((err)=>{
                    // TODO: An Error Has Occured Do Something Useful With it. Resp: "No" - TR 2020-09-28
                    console.log(`Failed To Update Closeout Data`)
                    console.log(err)
                    if(_.has(err, 'response')){
                        if(err.response.status == 403){
                            this.makeToast("Failed To Update Closeout Data - Access Denied", "Encounted an Endpoint Control error while attempting operation. This account does not have sufficient permissions to perform the requested action.", 'danger', 3600)
                        }else{
                            this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                        }
                    }else{
                        this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                    }
                    if(callback != null){
                        callback('error');
                    }
                })
            })
        },
        markAsBilled(callback = null){
            this.wo.children.forEach((itm)=>{
                var postData = {
                    billed: true
                };
                instance.post(process.env.VUE_APP_API_BASE_URL + "/atils/closeout/" + itm.event_id + ".json", postData)
                .then((response)=>{
                    itm.billed.completed = true;
                    itm.billed.by = "You";
                    itm.billed.on = new Date().toISOString();
                    this.wo.billed.completed = true;
                    this.wo.billed.by = "You";
                    this.wo.billed.on = new Date().toISOString();
                    if(callback != null){
                        callback();
                    }
                })
                .catch((err)=>{
                    // TODO: An Error Has Occured Do Something Useful With it. Resp: "No" - TR 2020-09-28
                    console.log(`Failed To Update Closeout Data`)
                    console.log(err)
                    if(_.has(err, 'response')){
                        if(err.response.status == 403){
                            this.makeToast("Failed To Update Closeout Data - Access Denied", "Encounted an Endpoint Control error while attempting operation. This account does not have sufficient permissions to perform the requested action.", 'danger', 3600)
                        }else{
                            this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                        }
                    }else{
                        this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                    }
                    if(callback != null){
                        callback();
                    }
                })
            })
        },
        markAsPaid(callback = null){
            this.wo.children.forEach((itm)=>{
                var postData = {
                    paid: true
                };
                instance.post(process.env.VUE_APP_API_BASE_URL + "/atils/closeout/" + itm.event_id + ".json", postData)
                .then((response)=>{
                    itm.paid.completed = true;
                    itm.paid.by = "You";
                    itm.paid.on = new Date().toISOString();
                    this.wo.paid.completed = true;
                    this.wo.paid.by = "You";
                    this.wo.paid.on = new Date().toISOString();
                    if(callback != null){
                        callback();
                    }
                })
                .catch((err)=>{
                    // TODO: An Error Has Occured Do Something Useful With it. Resp: "No" - TR 2020-09-28
                    console.log(`Failed To Update Closeout Data`)
                    console.log(err)
                    if(_.has(err, 'response')){
                        if(err.response.status == 403){
                            this.makeToast("Failed To Update Closeout Data - Access Denied", "Encounted an Endpoint Control error while attempting operation. This account does not have sufficient permissions to perform the requested action.", 'danger', 3600)
                        }else{
                            this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                        }
                    }else{
                        this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                    }
                    if(callback != null){
                        callback();
                    }
                })
            })
        },
        markAsClosed(callback = null){
            this.wo.children.forEach((itm)=>{
                var postData = {
                    closed: true,
                    deleteExt: true
                };
                instance.post(process.env.VUE_APP_API_BASE_URL + "/atils/closeout/" + itm.event_id + ".json", postData)
                .then((response)=>{
                    itm.closed.completed = true;
                    itm.closed.by = "You";
                    itm.closed.on = new Date().toISOString();
                    this.wo.closed.completed = true;
                    this.wo.closed.by = "You";
                    this.wo.closed.on = new Date().toISOString();
                    if(callback != null){
                        callback();
                    }
                })
                .catch((err)=>{
                    // TODO: An Error Has Occured Do Something Useful With it. Resp: "No" - TR 2020-09-28
                    console.log(`Failed To Update Closeout Data`)
                    console.log(err)
                    if(_.has(err, 'response')){
                        if(err.response.status == 403){
                            this.makeToast("Failed To Update Closeout Data - Access Denied", "Encounted an Endpoint Control error while attempting operation. This account does not have sufficient permissions to perform the requested action.", 'danger', 3600)
                        }else{
                            this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                        }
                    }else{
                        this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                    }
                    if(callback != null){
                        callback();
                    }
                })
            })
            
        },
        markAsDownloaded(callback = null){
            this.wo.children.forEach((itm)=>{
                if(itm.event_name == 'BFPA Test'){
                    this.downloadPDF(itm,()=>{
                        var postData = {
                            downloaded: true
                        };
                        instance.post(process.env.VUE_APP_API_BASE_URL + "/atils/closeout/" + itm.event_id + ".json", postData)
                        .then((response)=>{
                            itm.downloaded.completed = true;
                            itm.downloaded.by = "You";
                            itm.downloaded.on = new Date().toISOString();
                            this.wo.downloaded.completed = true;
                            this.wo.downloaded.by = "You";
                            this.wo.downloaded.on = new Date().toISOString();
                            if(callback != null){
                                callback();
                            }
                        })
                        .catch((err)=>{
                            // TODO: An Error Has Occured Do Something Useful With it. Resp: "No" - TR 2020-09-28
                            console.log(`Failed To Update Closeout Data`)
                            console.log(err)
                            if(_.has(err, 'response')){
                                if(err.response.status == 403){
                                    this.makeToast("Failed To Update Closeout Data - Access Denied", "Encounted an Endpoint Control error while attempting operation. This account does not have sufficient permissions to perform the requested action.", 'danger', 3600)
                                }else{
                                    this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                                }
                            }else{
                                this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                            }
                            if(callback != null){
                                callback();
                            }
                        })
                    }, (err)=>{
                        this.makeToast("Unable To Mark Downloaded", "PDF Failed To Download.", 'danger', 3600)
                    })
                }else{
                    var postData = {
                        downloaded: true
                    };
                    instance.post(process.env.VUE_APP_API_BASE_URL + "/atils/closeout/" + itm.event_id + ".json", postData)
                    .then((response)=>{
                        itm.downloaded.completed = true;
                        itm.downloaded.by = "You";
                        itm.downloaded.on = new Date().toISOString();
                        this.wo.downloaded.completed = true;
                        this.wo.downloaded.by = "You";
                        this.wo.downloaded.on = new Date().toISOString();
                        if(callback != null){
                            callback();
                        }
                    })
                    .catch((err)=>{
                        // TODO: An Error Has Occured Do Something Useful With it. Resp: "No" - TR 2020-09-28
                        console.log(`Failed To Update Closeout Data`)
                        console.log(err)
                        if(_.has(err, 'response')){
                            if(err.response.status == 403){
                                this.makeToast("Failed To Update Closeout Data - Access Denied", "Encounted an Endpoint Control error while attempting operation. This account does not have sufficient permissions to perform the requested action.", 'danger', 3600)
                            }else{
                                this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                            }
                        }else{
                            this.makeToast("Failed To Update Closeout Data", "An error has occured durring Update of this closeout record.", 'danger', 3600)
                        }
                        if(callback != null){
                            callback();
                        }
                    })
                }
            })
        },
        downloadPDF(itm, callback = null, errorCallback = null){
            console.log(itm);
            const pdfInstance = axios.create({
                timeout: 100000,
                headers: {
                    'Content-Type': 'application/json'
                    },
                crossdomain: true,
                withCredentials: true,
                responseType: 'blob'
            });
            if(itm.attachment_file_id != null && itm.closed.completed == true){
                //Get User Token and Fetch The Values Required For This Page
                pdfInstance.get(process.env.VUE_APP_FILE_API_BASE_URL + '/' + itm.attachment_file_id)
                .then(async (response) => {
                    fileDownload(response.data, itm.attachment_note);
                    itm.sent.downloadComplete = true;
                    if(callback != null){
                        callback();
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Download Official Report PDF", "Unable to Download Official Report PDF", 'danger', 3600);
                    if(error.response.status == 404){
                        console.log("File Not Found");
                        if(errorCallback != null){
                            errorCallback(404);
                        }
                    }else{
                        if(errorCallback != null){
                            errorCallback();
                        }
                    }
                    console.log(error);
                });
            }else{
                //Get User Token and Fetch The Values Required For This Page
                pdfInstance.get(process.env.VUE_APP_API_BASE_URL + '/download/pdf/test/' + itm.event_id + '.pdf')
                .then(async (response) => {
                    console.log(response);
                    var contentDisp = response.headers["content-disposition"];
                    if(contentDisp != null){
                        var fileNameIndex = contentDisp.indexOf("filename=");
                        if(fileNameIndex == -1){
                            // Doesnt Have A FileName
                            fileDownload(response.data, 'Test Report ' + this.event_id + '.pdf');
                            itm.sent.downloadComplete = true;
                        }else{
                            // Has A FileName
                            var expectedFileName = contentDisp.match(/(?<=filename=").+(?=")/g)
                            console.log(expectedFileName);
                            if(expectedFileName.length > 0){
                                fileDownload(response.data, expectedFileName[0]);
                                itm.sent.downloadComplete = true;
                            }else{
                                fileDownload(response.data, 'Test Report ' + this.event_id + '.pdf');
                                itm.sent.downloadComplete = true;
                            }
                        }
                    }else{
                        // No File Name Specified
                        fileDownload(response.data, 'Test Report ' + this.event_id + '.pdf');
                        itm.sent.downloadComplete = true;
                    }
                    if(callback != null){
                        callback();
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.makeToast("Failed To Generate PDF", "Failed To Generate PDF", 'danger', 3600)
                    if(error.response.status == 404){
                        console.log("Unable To Generate PDF Report");
                    }
                    if(errorCallback != null){
                        errorCallback();
                    }
                });
            }
            
            
        },
        downloadOfficialPDF(itm, callback = null){
            const pdfInstance = axios.create({
                timeout: 10000,
                headers: {
                    'Content-Type': 'application/json'
                    },
                crossdomain: true,
                withCredentials: true,
                responseType: 'blob'
            });
            //Get User Token and Fetch The Values Required For This Page
            pdfInstance.get(process.env.VUE_APP_FILE_API_BASE_URL + '/' + itm.attachment_file_id)
            .then(async (response) => {
                console.log(response);
                if(itm.attachment_note != null){
                    fileDownload(response.data, itm.attachment_note);
                }else{
                    // No File Name Specified
                    fileDownload(response.data, 'Test Report ' + itm.event_id + '.pdf');
                }
                if(callback != null){
                    callback();
                }
            })
            .catch((error) => {
                console.log(error)
                this.makeToast("Failed To Download Official Report PDF", "Failed To Download Official Report PDF", 'danger', 3600)
                if(error.response.status == 404){
                    console.log("Unable To Download Official Report PDF");
                }
                if(callback != null){
                    callback();
                }
            });
            
        },
        performWhenSelected(callback){
            if(this.isSelected){
                callback();
            }
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null, timer = 20){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: timer * 1000,
                appendToast: true
            })
        }
    },
    computed: {
        lastModified: function () {
            var max = "0000-00-00T00:00:00"
            this.wo.children.forEach((child)=>{
                if(child.imported_on > max){
                    max = child.imported_on;
                }
            });
            return this.parseTimestampToDateTime(max);
        },
        allChildrenClosed: function(){
            var closed = true
            this.wo.children.forEach((child)=>{
                if(child.closed.completed == false){
                    closed = false;
                }
            });
            return closed;
        },
        downloadEligible: function(){
            if(this.allChildrenClosed){
                var hasDownload = false;
                this.wo.children.forEach((child)=>{
                    if(child.event_name == 'BFPA Test'){
                        hasDownload = true;
                    }
                })
                return hasDownload;
            }else{
                return false;
            }
        },
    },
    watch:{
    },
    mounted(){
        
    },
    created(){
    },
    beforeDestroy(){
        
    },
    beforeMount (){
        // console.log(this.wo);
    },
}
</script>

<style scoped>
.line-up-col{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}
.line-up-btn{
    flex: 1 1 auto;
}
.main-wo-container{
    background-color: #F0F0F0;
    border-style: solid;
    border-color: #C4C4C4;
    border-width: 2px;
}
.main-wo-container-selected{
    background-color: #A3D7FE;
    border-style: solid;
    border-color: #68BEFF;
    border-width: 2px;
}
</style>

